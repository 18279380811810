<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb :items="breadcrumbItems" />
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_05.png"
            alt=""
          />
        </div>
      </v-row>

      <v-row
        class="container d-flex justify-center align-center d-flex d-md-none"
      >
        <v-col cols="12" md="8" class="">
          <div class="iframe__box">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AYqtbwAumww"
              title="O ofício do carpinteiro"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p style="font-size: 0.6em; text-align: center;">
            Entrevista com o Sr. Dario, atingido da Comunidade de Camargos.
            Dario e seu pai trabalharam como carpinteiros, utilizando-se de
            técnicas tradicionais de encaixe, taipa e adobe, aprendidas em
            Camargos. Ambos trabalharam na restauração do patrimônio edificado
            de Mariana, suas igrejas e casarios.
          </p>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  São vários os ofícios realizados nas comunidades atingidas que
                  atravessaram os séculos desde o período colonial; entre eles,
                  destaca-se o de carpinteiro, profissional que possui as
                  habilidades da carpintaria, dedicado à fabricação de elementos
                  de madeira da construção civil de modo geral. A palavra
                  carpintaria tem origem do latim “carpenteria”, que se refere
                  ao ofício de lavrar e aparelhar a madeira para construção.
                </p>
                <p>
                  O carpinteiro é o ensamblador ou samblador, o oficial que
                  trabalha e junta a madeira, que corta a meia esquadria.
                </p>
                <p>
                  O ofício da carpintaria é considerado parte integrante das
                  técnicas tradicionais, em que há uma maneira de se executar
                  desenvolvida há tempos a partir da experiência no canteiro de
                  obras, ou seja, observando-se e aprimorando-se aquele modo de
                  fazer que é praticamente local, regional.
                </p>
                <p>
                  As condições locais e as matérias-primas encontradas na região
                  transformaram os oficiais que trabalhavam com madeira no grupo
                  de profissionais mais numerosos da Capitania de Minas Gerais.
                  No século XVIII, cerca de 40% dos mestres em Minas eram
                  carpinteiros, marceneiros ou carapinas (carpinteiro de
                  construções rurais). O trabalho com a madeira foi privilegiado
                  nos setecentos tanto nas estruturas quanto nos elementos
                  decorativos, de modo geral, referente à construção das casas
                  por ser um material mais barato que a pedra.
                </p>
                <p>
                  A partir dos recenseamentos produzidos nas décadas de 1820 e
                  1830 em Teixeira (Paracatu),
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_camargos' }"
                    >Camargos</router-link
                  >
                  e
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'bento_rodrigues' }"
                    >Bento Rodrigues</router-link
                  >, é possível observar o processo de adaptação na região
                  atravessada pelo Rio Gualaxo do Norte diante da decadência da
                  extração de ouro e adaptação das comunidades a outras
                  atividades econômicas. A Freguesia de Camargos e Bento
                  Rodrigues, conforme o censo de 1831, possuíam entre as
                  “ocupações” listadas o ofício de carpinteiro.
                </p>
                <p>
                  No século XVIII, havia uma significativa presença de
                  profissionais portugueses na região mineira. Entre vários
                  nomes de arquitetos e artistas mencionados no documento do
                  Vereador de Mariana capitão Joaquim José da silva, é provável
                  que Antônio Francisco Lisboa seja o único efetivamente nascido
                  no Brasil; os demais seriam, em sua maioria, de origem
                  portuguesa. A vinda de portugueses para a região das minas,
                  onde puderam contribuir com seu trabalho, se reflete também,
                  mesmo que de forma embrionária, na formação de artesãos e
                  artistas locais.
                </p>
                <p>
                  Na capitania de Minas Gerais no século XVIII, o ensino das
                  artes e ofícios se dava no próprio canteiro de obras, local
                  comum da atuação em parceria de artistas e artífices que,
                  muitas vezes, levava à constituição de vínculos de parentesco.
                  Com o passar do tempo, surgiram as oficinas de famílias, assim
                  como a continuidade do trabalho de um mestre pelas pessoas
                  escravizadas que ele possuía. Interessante ressaltar que a
                  divisão de obras entre os marceneiros, carpinteiros, torneiros
                  e entalhadores foi muito mais teórica do que realmente
                  prática, tendo sempre havido intervenções de uns nas tarefas
                  dos outros.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom d-none d-md-flex">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="">
          <div class="iframe__box">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AYqtbwAumww"
              title="O ofício do carpinteiro"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p style="font-size: 0.6em; text-align: center;">
            Entrevista com o Sr. Dario, atingido da Comunidade de Camargos.
            Dario e seu pai trabalharam como carpinteiros, utilizando-se de
            técnicas tradicionais de encaixe, taipa e adobe, aprendidas em
            Camargos. Ambos trabalharam na restauração do patrimônio edificado
            de Mariana, suas igrejas e casarios.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-start flex-column"
        >
          <Card
            borderColor="#426E51"
            titleColor="#426E51"
            title="A História de vida do Sr. Dario está relacionada ao ofício de carpinteiro"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                Sr. Dario Jorge Pereira, atingido da Comunidade de Camargos, faz
                parte de uma família numerosa originária do próprio distrito,
                desde os avós e bisavós tanto maternos quanto paternos. Os pais
                do Sr. Dario tiveram dez filhos, sendo oito ainda vivos. Devido
                às dificuldades de se encontrar serviço no distrito, o pai, Sr.
                José Matias Ferreira, que era carpinteiro, foi trabalhar na sede
                de Mariana. Inicialmente, foi contratado por uma empresa de
                mineração situada em Mina del Rei para executar porteira,
                caixote para colocar amostra e para transporte de minério.
                Entretanto, todo sábado ele retornava para Camargos para visitar
                a família e na segunda-feira voltava para trabalhar em Mariana.
              </p>
              <p>
                Nesse período, o Sr. José foi contratado pelo IPHAN para
                realizar serviços nas igrejas que faziam parte de seu acervo
                tombado. Como estava difícil o cotidiano de trabalhar na sede e
                residir no distrito, mudou-se com a família para Mariana. Na
                época, Sr. Dario estava com sete anos de idade. Ele contou com
                orgulho que o pai se aposentou no “Patrimônio” e ele próprio
                trabalhou na instituição com o Sr. Juquinha Carpinteiro, como o
                pai era conhecido, já que realizava serviços de servente de
                pedreiro.
              </p>
              <p>
                O atingido contou que a especialidade do pai era executar
                telhados, estrutura (colunas e vigas de madeira) e fechamento de
                pau a pique. As peças eram feitas de forma manual com
                ferramentas, a exemplo de portais (marcos ou enquadramento de
                portas), folhas de portas e janelas.
              </p>
              <p>
                Sr. Dario relatou que a madeira utilizada na construção das
                casas era colhida nas matas, lavrada na mata e levada para a
                cidade, no local onde estava sendo realizada a obra e ali se
                fabricavam as peças de estrutura, os portais, as portas e
                janelas, tudo de forma manual. Da mesma forma eram feitas as
                peças do telhado, artesanalmente.
              </p>
              <p>
                As estruturas de telhado que Sr. Juquinha Carpinteiro executava
                eram com tesouras de madeira, bem como capa de cangalha, 4
                águas, uma água, de acordo com o telhado. Tanto reformava quanto
                executava um novo. Quanto aos telhados das igrejas, eram
                majoritariamente do tipo capa de cangalha, Sr. Juquinha fazia
                também o madeiramento da cobertura das torres e amarrava as
                telhas. Entre as igrejas de Mariana que Sr. Dario trabalhou com
                o pai, ele citou a Confraria (Capela de Nossa Senhora dos
                Anjos), Igreja do Rosário, junto com a equipe toda do
                “Patrimônio”, os carpinteiros, pedreiros, pintores e serventes.
                Relembrou que na Confraria foram executados tijolos de adobe
                para restauração de parede, na dimensão de 40X40cm. Além das
                igrejas, a equipe de obras realizava serviços de restauro e
                reformas nas edificações residenciais do conjunto tombado. O Sr.
                Juquinha era o carpinteiro da equipe. Nascido em 1895, faleceu
                com 85 anos. O Sr. Dario contou com orgulho que o pai conhecia
                vários encaixes de madeira sem uso de pregos ou parafusos.
              </p>
              <p>
                O atingido relatou que na casa que possui em Camargos, as
                paredes internas são todas de pau a pique. As externas, em uma
                reforma, foram substituídas por tijolinhos. O Sr Dario citou
                como ferramentas utilizadas pelo pai o torno manual, específico
                para peças com acabamento mais elaborado e as mais utilizadas
                eram o formão, o limatão (utilizado para arredondar). Em relação
                aos tipos de madeira, o Sr. Dario ressaltou o cedro, a canela,
                madeira boa para telhado, jacarandá e braúna para peças
                estruturais como colunas e vigas, bem como barrotes de
                sustentação de piso.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="">
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_alicerce' }"
            color="#EDBD43"
          >
            <strong>Conhecer "O Alicerce"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas",
          href: "conteudos_tematicos_casas",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}

.iframe__box {
  position: relative;
  padding-bottom: 56.1%;
  height: 0;
  overflow: hidden;

  border-radius: 15px;
}

.iframe__box iframe,
.iframe__box object,
.iframe__box embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
